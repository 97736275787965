#page-cat-example {
    background-color: #EBE6CD;
}
#page-cat-example .pushin {
    height: 5000px;
}

.wall {
    position: absolute;
    width: 40%;
    top: 41.3%
}

.light {
    width: 13%;
    position: absolute;
    top: 33%;
}

.chair {
    width: 30%;
    position: absolute;
    top: 52%;
    left: 0%;
}

.tv {
    width: 30%;
    position: absolute;
    top: 51%;
    right: 0%;
}

.sofa {
    width: 40%;
    position: absolute;
    bottom: 40%;
}

.floor {
    background-color: #BCC0C9;
    width: 100%;
    height: 43%;
    position: absolute;
    bottom: 0;
}

.ceiling {
    background-color: #C0A592;
    width: 100%;
    height: 42.2%;
    position: absolute;
    top: 0;
}

.plant {
    width: 10%;
    position: absolute;
    bottom: 40%;
    right: 31%;
}

.table {
    background-color: #3A596B;
    width: 100%;
    height: 40.5%;
    position: absolute;
    bottom: 0;
}